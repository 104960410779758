<!--新闻动态列表-->
<template>
    <div id="newsList">
        <div class="newsList">
          <div class="newsLi" v-for="(item,k) in newsList" :key='k'>
            <div class="news_body" >
                <div class="news_img">
                  <img :src="item.img" />
                </div>
                <div>
                    <p class="news_title">
                        {{item.title}}
                    </p>
                    <p class="news_introduce">
                        {{item.introduce}}
                    </p>
                </div>
            </div>
            <div class="news_time">
                {{item.time}}
            </div>
          </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "newsList",
  data () {
    return {
      
    }
  },
   props: {
    newsList: {
      type: Array,
    },
  },
};
</script>

<style scoped lang="scss">
.newsList{
  .newsLi{
    border-bottom: 1px solid #6666;
    display:flex;
    .news_body{
      flex:1;
      display:flex;
      .news_img{
        padding:1vh 1vw;
      }
      .news_title{
        font-size:1.4rem;
        font-weight:normal;
        margin:1vh 0;
      }
      .news_introduce{
        font-size:1rem;
        color:#9999;
      }
    }
    .news_time{
      width:10vw;
      color:#6666;
      font-size:0.6rem;
      display:flex;
      align-items:center;
      justify-content:center;
    }
  }
}

</style>
