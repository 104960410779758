<!--新闻动态-->
<template>
    <div style="padding: 2vh 11vw;">
      <newsList :newsList="news_list"></newsList>
    </div>
</template>

<script>
import newsList from "./components/newsList";
export default {
  name: "news",
  mounted() {
  },
  components: {
    newsList,
  },
  data() {
    return {
      news_list:[
        {
            img: require("../../assets/images/jiangxin.jpg"),
            title:"公司获得信息技术服务管理体系等2项ISO认证",
            introduce:"2020年11月27日，公司获得信息技术服务管理体系、信息安全管理体系等2项ISO认证。",
            time:"2020-11-27"
        },
        {
            img: require("../../assets/images/jiangxin.jpg"),
            title:"公司通过厦门双软企业认证",
            introduce:"2020年9月15日，公司通过软件产品登记、软件企业认定审核",
            time:"2020-09-15"
        },
        {
            img: require("../../assets/images/jiangxin.jpg"),
            title:"麦乐峰科技成功挂牌海峡科创板",
            introduce:"2020年9月2日，麦乐峰科技成功挂牌海峡科创板（公司简称：麦乐峰，股权代码：160001）",
            time:"2020-09-02"
        },
        {
            img: require("../../assets/images/jiangxin.jpg"),
            title:"公司获得CMMI软件成熟度三级认证",
            introduce:"2019年12月12日，公司顺利通过CMMI三级认证。",
            time:"2019-12-12"
        },
        {
            img: require("../../assets/images/jiangxin.jpg"),
            title:"军民融合┃“戎和园”产业落地项目正式提上日程",
            introduce:"2019年9月9日下午，“戎和园”产业落地战略合作项目正式签约。",
            time:"2019-09-09"
        },
        {
            img: require("../../assets/images/jiangxin.jpg"),
            title:"麦乐峰与航天科工集团举行战略合作签约仪式",
            introduce:"2019年9月9日上午，麦乐峰与航天科工集团举行战略合作签约仪式。",
            time:"2019-09-09"
        },
        {
            img: require("../../assets/images/jiangxin.jpg"),
            title:"公司获得质量管理体系等3项ISO认证",
            introduce:"2019年8月28日，公司获得质量管理体系、环境管理体系、中国职业健康安全管理体系等3项ISO认证。",
            time:"2019-08-28"
        },
        {
            img: require("../../assets/images/jiangxin.jpg"),
            title:"社会公益┃麦乐峰公司积极响应无偿献血活动",
            introduce:"2019年8月2日下午，麦乐峰公司积极组织员工参与地区党工委组织的无偿献血活动。",
            time:"2019-08-02"
        },
        {
            img: require("../../assets/images/jiangxin.jpg"),
            title:"公司获得AAA企业信用等级认证",
            introduce:"2019年7月24日，公司获得AAA企业信用等级认证",
            time:"2019-07-24"
        },
        {
            img: require("../../assets/images/jiangxin.jpg"),
            title:"麦乐峰公司名称变更声明",
            introduce:"2018年12月18日，公司名称由麦乐峰（厦门）电子商务有限公司变更为麦乐峰（厦门）智能科技有限公司。",
            time:"2018-12-18"
        },
        {
            img: require("../../assets/images/jiangxin.jpg"),
            title:"公司获得国家级高新技术企业认证",
            introduce:"2017年10月10日，公司获得国家级高新技术企业认证。",
            time:"2017-10-10"
        },
        {
            img: require("../../assets/images/jiangxin.jpg"),
            title:"麦乐峰公司成立",
            introduce:"2011年4月19日，麦乐峰公司在厦门市思明区市场监督管理局登记成立。",
            time:"2011-04-19"
        },
      ]
    };
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
</style>
